<template>
  <v-window v-model="step" class="elevation-1">
    <!-- first step -->
    <v-window-item :value="1">
      <v-card class="pa-6" color="foreground">
        <v-card-title class="px-0 pt-0">
          <v-btn class="rounded-lg mr-3" icon @click="navigation('/main')">
            <v-icon> mdi-arrow-left</v-icon>
          </v-btn>

          Dados Pessoais
        </v-card-title>

        <!-- personal data -->
        <PersonalData />

        <h1 class="mb-4 text-h6">Pagamento por PIX</h1>

        <!-- awaiting -->
        <v-list-item v-if="awaitingMethods.includes('PIX')" class="accent">
          <v-list-item-title class="text-center secondary_text--text">
            Existe uma transação pendente,
            <a @click="continueTransaction()">clique para continuar</a>
          </v-list-item-title>
        </v-list-item>

        <!-- form -->
        <v-form
          v-else-if="!lockedMethods['PIX']"
          v-model="valid"
          ref="form"
          autocomplete="off"
        >
          <p class="pix-form-text-info">
            <span>
              Para garantir o sucesso da sua transação, por favor, verifique se
              o
              <span class="pix-form-text-info-highlight">CPF</span>
              de quem realizará o
              <span class="pix-form-text-info-highlight">pagamento</span>
              está correto.
            </span>

            <br />

            <span>
              Caso esteja realizando o
              <span class="pix-form-text-info-highlight">pagamento</span>
              através de uma
              <span class="pix-form-text-info-highlight">conta bancária</span>
              de uma outra pessoa, certifique-se de inserir o
              <span class="pix-form-text-info-highlight">CPF</span>
              do titular da conta. Isso evita possíveis
              <span class="pix-form-text-info-highlight">
                problemas no pagamento
              </span>
              e garantirá uma experiência tranquila. Obrigado pela compreensão
            </span>
          </p>

          <v-switch
            v-model="form.forSelf"
            class="mb-2"
            :label="`O pagamento ${
              form.forSelf ? '' : 'não'
            } será feito por mim`"
          />

          <v-text-field
            v-if="!form.forSelf"
            v-model="form.document"
            v-mask="'###.###.###-##'"
            label="CPF do titular do pagamento"
            class="mb-2"
            background-color="accent"
            :rules="ruleDocument"
            autofocus
            outlined
          >
            <template v-slot:append>
              <v-icon color="secondary"> mdi-card-bulleted-outline</v-icon>
            </template>
          </v-text-field>

          <v-btn color="primary" block large @click="submit()">
            Continuar Pagamento
          </v-btn>
        </v-form>

        <!-- unavaiable -->
        <v-list-item v-else class="accent">
          <v-list-item-title class="text-center secondary_text--text">
            Serviço indisponível para PIX
          </v-list-item-title>
        </v-list-item>
      </v-card>
    </v-window-item>

    <!-- second step -->
    <v-window-item :value="2">
      <v-card class="pa-6" color="foreground">
        <v-card-title class="px-0 pt-0">
          <v-btn
            v-if="!transactionPerformed"
            class="rounded-lg mr-3"
            icon
            @click="navigation(`/checkout`)"
          >
            <v-icon> mdi-arrow-left</v-icon>
          </v-btn>
          Código PIX
          <v-spacer />
          <span class="caption secondary_text--text mb-4 mx-4">
            Aguardando pagamento
            <v-progress-circular class="ms-2" indeterminate size="20" />
          </span>
        </v-card-title>

        <span class="text-body-1 px-0 mt-2 secondary_text--text">
          No aplicativo do seu banco, selecione
          <span class="secondary--text">PIX</span>.
        </span>

        <br />

        <p v-if="!_isMobile" class="secondary_text--text mt-3">
          Escolha a opção <span class="secondary--text">QR Code</span>
          e aponte a câmera para o
          <span class="secondary--text">QR Code</span>
          para realizar o <span class="secondary--text">pagamento</span>
        </p>

        <!-- pix -->
        <section class="my-3">
          <!-- qr code -->
          <v-row class="justify-center mb-8" no-gutters v-if="!_isMobile">
            <vue-qr
              :text="transaction.code"
              :size="200"
              class="rounded-lg elevation-3"
            />
          </v-row>

          <p class="secondary_text--text">
            <span v-if="!_isMobile">Ou escolha</span>
            <span v-else>Escolha</span> a opção
            <span class="secondary--text">Copia e Cola</span>
            e insira o <span class="secondary--text">código</span> abaixo para
            realizar o <span class="secondary--text">pagamento</span>.
          </p>

          <div class="mb-4 d-flex align-center">
            <input
              v-model="transaction.code"
              id="code"
              class="centered-input primary_text--text font-weight-regular text-caption accent pa-3"
              readonly
              @focus="copyCode()"
            />
            <v-btn color="secondary" text @click="copyCode()">
              <v-icon> mdi-content-copy</v-icon>
            </v-btn>
          </div>

          <div class="d-flex flex-column">
            <div class="d-flex justify-center">
              <v-btn text @click="showCNPJ = !showCNPJ">
                <span class="caption font-weight-bold text-capitalize"
                  >outros métodos</span
                >
                <v-icon right
                  >mdi-chevron-{{ showCNPJ ? "up" : "down" }}</v-icon
                >
              </v-btn>
            </div>
            <div
              v-if="showCNPJ"
              class="d-flex align-center justify-center pa-2"
            >
              <!-- cnpj -->
              <span class="mx-2 caption font-weight-bold"
                >Chave PIX <span class="secondary--text">(CNPJ)</span></span
              >
              <input
                v-model="jwtDecoded.transaction.cnpj"
                id="cnpj"
                class="text-center primary_text--text font-weight-bold text-caption accent pa-2"
                readonly
                @focus="copyCnpj()"
              />
              <v-btn color="secondary" text @click="copyCnpj()">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </div>
        </section>

        <!-- receipt -->
        <v-card class="rounded-lg mb-6 px-4 pt-4" color="accent" flat>
          <p class="primary_text--text text-body-1">
            Para
            <span class="secondary--text">agilizar</span> o processo do seu
            pagamento, anexe o <span class="secondary--text">comprovante</span>.
          </p>

          <v-form v-model="valid" ref="form" autocomplete="off">
            <v-file-input
              v-model="file"
              accept="image/png, image/jpeg, image/bmp, image/jpg, .pdf"
              placeholder="Anexar comprovante de pagamento"
              outlined
            />
          </v-form>
        </v-card>

        <v-btn class="mb-6" color="primary" large block @click="finishHim()">
          Já fiz o pagamento
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>

        <div v-if="jwtDecoded.transaction.redirectURL" class="text-center">
          <a :href="jwtDecoded.transaction.redirectURL" class="text-button">
            Retornar para o site
          </a>
        </div>
      </v-card>
    </v-window-item>

    <loader-hover v-if="loading" />
  </v-window>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { pix, sendReceipt } from "@/services/payment";
import { getTransaction } from "@/services/checkout";
import { displayAlert } from "@/utils";
import { mask } from "vue-the-mask";
import VueQr from "vue-qr";
import PersonalData from "@/components/checkout/PersonalData";

export default {
  data() {
    return {
      interval: null,
      loading: false,
      valid: false,
      validReceipt: false,
      showCNPJ: false,
      step: 1,
      form: {
        forSelf: true,
        document: "",
      },
      transaction: {},
      file: null,
    };
  },

  components: {
    VueQr,
    PersonalData,
  },

  directives: { mask },

  beforeMount() {
    // prevent access this route with transaction performed
    if (this.transactionPerformed && this.jwtDecoded) {
      const method = this.jwtDecoded.transaction.method;

      if (method != "PIX") {
        return this.$router.push({
          path: `/checkout/payment/${method.toLowerCase()}`,
        });
      }
    }

    if (this.current) this.handleCurrent();

    this.getCurrentTransaction();

    this.interval = setInterval(() => this.getCurrentTransaction(), 5000);
  },

  mounted() {
    // this.submit();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  computed: {
    ...mapState([
      "jwtDecoded",
      "current",
      "transactionPerformed",
      "awaitingMethods",
      "lockedMethods",
    ]),

    rule() {
      return [(v) => !!v || "Campo Obrigatório"];
    },

    ruleDocument() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length === 14 || "CPF Inválido",
      ];
    },

    _isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  watch: {
    "current.transaction.transactionStatusId": function (status) {
      console.log("new status", status);
      if (status > 1) location.reload();
    },
  },

  methods: {
    ...mapActions(["setCurrent", "setTransactionPerformed"]),

    // submit pix
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          transactionId: this.jwtDecoded.transaction.ref,
          document: this.form.forSelf
            ? this.jwtDecoded.customer.document
            : this.form.document.replaceAll(".", "").replace("-", ""),
        };

        const paymentMethodIsDefined =
          !!this.current?.transaction?.paymentMethod;
        if (paymentMethodIsDefined) {
          return;
        }

        await pix(payload).then((res) => {
          this.transaction = {
            id: res.body.data.id,
            transactionId: res.body.data.transactionId,
            code: res.body.data.code,
          };

          this.setTransactionPerformed(true);

          // get current transaction to commit
          this.getCurrentTransaction();

          this.step = 2;
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    // send receipt to finish
    async finishHim() {
      if (
        this.current.data.document !== this.jwtDecoded.customer.document &&
        !this.file
      ) {
        return this.displayAlert(
          "Envio do comprovante obrigatório para PIX em titularidade de terceiros",
          2
        );
      }

      if (!this.file) {
        // navigation to review
        return this.navigation(
          `/checkout/summary/${this.transaction.transactionId}`
        );
      }

      try {
        this.loading = true;

        const formData = new FormData();
        formData.append("file", this.file);

        const headers = {
          ref: this.transaction.transactionId,
        };

        await sendReceipt(formData, headers).then(() => {
          this.displayAlert("Comprovante enviado com sucesso");

          // navigation to review
          this.navigation(
            `/checkout/summary/${this.transaction.transactionId}`
          );
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    // get and commit current transaction
    async getCurrentTransaction() {
      try {
        const payload = {
          ref: this.jwtDecoded.transaction.ref,
          document: this.jwtDecoded.customer.document,
        };

        await getTransaction(payload).then((res) => {
          this.setCurrent(res.body); // commit on storage
          const status = res.body?.transaction?.transactionStatusId || 0;
          if (status > 1) location.reload();
        });
      } catch (err) {
        console.log(err);
      }
    },

    // handle current transaction
    handleCurrent() {
      if (!this.current?.data) {
        return;
      }

      this.transaction = {
        id: this.current.data.id,
        transactionId: this.current.data.transactionId,
        code: this.current.data.code,
      };

      this.step = 2;
    },

    // copy pix code
    copyCode() {
      var textBox = document.getElementById("code");
      textBox.select();
      document.execCommand("copy");

      this.displayAlert("Código copiado com sucesso!");
    },

    // copy cnpj
    copyCnpj() {
      var textBox = document.getElementById("cnpj");
      textBox.select();

      textBox.select();
      document.execCommand("copy");

      this.displayAlert("CNPJ copiado com sucesso!");
    },

    // continue current transactions
    continueTransaction() {
      // continue transaction
      const id = this.jwtDecoded.awaitingTransactions.find(
        (e) => e.paymentMethod === "PIX"
      ).id;

      this.$root.$emit("continue-transaction", id);
    },

    // router push
    navigation(path) {
      this.$router.push({ path: path }).catch(() => {});
    },

    displayAlert,
  },
};
</script>

<style lang="scss" scoped>
.centered-input {
  text-align: center;
  width: 100%;
  border-radius: 4px;
}

.pix-form-text-info {
  padding: 12px;
  font-size: 1rem;
  text-align: justify;
  line-height: 1.4rem;
  font-weight: 400;
  border: 1px solid var(--v-primary-base);
  border-radius: 4px;

  &-highlight {
    // font-weight: 500;
    color: var(--v-primary-base);
  }
}
</style>
