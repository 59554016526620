<template>
  <v-list class="transparent px-0 mb-4" nav>
    <v-list-item class="accent">
      <v-list-item-icon class="ml-2 mr-4">
        <v-icon color="secondary" class="mt-1"> mdi-account-outline </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>
          {{ jwtDecoded.customer.name }}
        </v-list-item-title>

        <v-list-item-subtitle> Nome </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item class="accent">
      <v-list-item-icon class="ml-2 mr-4">
        <v-icon color="secondary" class="mt-1"> mdi-email-outline </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>
          {{ jwtDecoded.customer.email }}
        </v-list-item-title>

        <v-list-item-subtitle> E-mail </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item class="accent">
      <v-list-item-icon class="ml-2 mr-4">
        <v-icon color="secondary" class="mt-1">
          mdi-card-account-details-outline
        </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>
          {{ jwtDecoded.customer.document }}
        </v-list-item-title>

        <v-list-item-subtitle> Documento </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["jwtDecoded"]),
  },
};
</script>

<style>
</style>