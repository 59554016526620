import { request } from "./index";

export const bankSlip = (data) => request("post", "/boleto/new", data);

export const creditCard = (data) => request("post", "/card/credit", data);

export const pix = (data) => request("post", "/pix/new", data);

export const picpay = (data) => request("post", "/picpay/new", data);

export const transfer = (data) => request("post", "/transfer/new", data);

export const sendReceipt = (formData, headers) =>
  request("post", "/transaction/append", formData, headers);

export const duplicateBankSlip = (data) =>
  request("get", `/boleto/html?ref=${data}`);
